import { render, staticRenderFns } from "./SelectedFiilters.vue?vue&type=template&id=fa2ab124&scoped=true"
import script from "./SelectedFiilters.vue?vue&type=script&lang=js"
export * from "./SelectedFiilters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa2ab124",
  null
  
)

export default component.exports