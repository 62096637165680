<template>
    <div class="carlistings" :class="modifier">

        <div class="carlistings__header">
            <div class="carlistings__headertext">

                <div v-if="$router.currentRoute.name == 'used-cars'">
                    <h1 v-if="singleMakeModel == null"> {{ mainTitle }} </h1>
                    <h1 v-if="singleMakeModel !== null">Used {{singleMakeModel}} Cars</h1>
                    <small v-if="resultsLoaded && this.total > 0 && singleMakeModel == null">Found {{ formattedTotal }} {{singleMakeModel}} Used Cars<span>, Showing {{ this.from }}-{{ this.to }} below.</span></small>
                    <small v-if="resultsLoaded && this.total > 0 && singleMakeModel !== null">Found {{  formattedTotal }} {{singleMakeModel}} Cars<span>, Showing {{ this.from }}-{{ this.to }} below.</span></small>
                </div>

                <div v-if="$router.currentRoute.name == 'dealer'">
                    <h1>{{ mainTitle }}</h1>
                    <small v-if="resultsLoaded && this.total > 0">Found {{ formattedTotal }} {{ mainSubtitle }}<span>, Showing {{ this.from }}-{{ this.to }} below.</span></small>
                </div>


                <small v-if="this.total == 0">There are no vehicles matching this search. Try changing your search criteria.</small>
            </div>

<!--            <div class="carlistings__reset-search">-->
<!--                <a href="javascript:void(0)" @click="resetFilters" :class="{'enabled': this.$router.currentRoute.path.replace('/s-1', '') != '/used-cars', 'disabled': this.$router.currentRoute.path.replace('/s-1', '') == '/used-cars'}">-->
<!--                    reset search filters-->
<!--                </a>-->
<!--            </div>-->

            <div class="mobileonly">
                <SelectedFilters />
            </div>

            <related-search></related-search>

             <div class="carlistings__form" v-if="showEmailAlert()">
                <div class="carlistings__form_left">Email me price drops and new listings for these results</div>
                <div class="carlistings__form_right">
                    <form ref="form" action="javascript:void(0)">
                        <input type="email" v-on:keyup.enter="subscribeEmailAlert()" name="email" id="email" placeholder="Email" class="form-control" v-model="email" />
                        <input type="submit" v-on:click="subscribeEmailAlert()" value="Subscribe" />
                    </form>
                </div>
            </div>
            <div class="carlistings__viewtoggle">
                <small v-if="resultsLoaded && this.total > 0">Found {{ formattedTotal }} Used Cars </small>
                <small v-if="this.total == 0">There are no vehicles matching this search. Try changing your search criteria or clear your search filters.</small>
                <div class="carlistings__viewtoggle_icons">
                    <div @click="setListMode('list')" href="javascript:void(0)" class="carlistings__toggle carlistings__toggle--list" data-class="carlistings-toggle-list" ></div>
                    <div @click="setListMode('card')" href="javascript:void(0)" class="carlistings__toggle carlistings__toggle--card" data-class="carlistings-toggle-card"></div>
                </div>
            </div>

            <div class="carlistings__sort" v-if="!hideFor404">

                <form>
                    <label>Sort By:</label>
                    <select name="sort_by"
                            class="form-control"
                            @change="orderByChange"
                            :value="searchOrderBy"
                    >
                        <option v-for="(item, index) in arrSortBy" :value="item.value" v-bind:key="index">{{ item.text }}</option>
                    </select>
                </form>
            </div>
        </div>


        <div class="carlistings__listings">
            <car-listings-car v-for="(car, index) in searchResultData" :car="car._source" :index="index" v-bind:key="index"> </car-listings-car>
            <div class="pagination" v-if="this.page <= Math.ceil(this.total / this.perPage)">
                <pagination
                        v-if="!hideFor404"
                        v-model="page"
                        :records="total > this.maxPages * parseInt(perPage) ? this.maxPages * parseInt(perPage) : total"
                        :options="paginationOptions"
                        :per-page="parseInt(perPage)"
                        :vuex=false
                        @paginate="pageChanged"
                >
                </pagination>
            </div>
            <a class="reset-filters" v-if="this.total == 0 && this.$router.currentRoute.path.replace('/s-1', '') != '/used-cars' && this.$route.name != 'dealer'" href="javascript:void(0)" @click="resetFilters()">
               reset search filters
            </a>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex'
    import CarListingsCar from "./CarListingsCar"
    import RelatedSearch from "./RelatedSearch"
    import Pagination from 'vue-pagination-2'
    import TerrificPagination from "../paginator/TerrificPagination"
    import {isMobile} from "mobile-device-detect";
    import SelectedFilters from "@/components/partials/SelectedFiilters.vue";
    var numeral = require('numeral')

    export default {
        name: "CarListings",
        components: {
            SelectedFilters,
            RelatedSearch,
            CarListingsCar,
            Pagination
        },
        props: ['slug', 'modifier', 'currPage'],
        data: function() {
            return {
                paginationModel: 1,
                email:null,
                likes:[],
                paginationOptions:{
                    template: TerrificPagination,
                    edgeNavigation:true,
                    chunk:6,
                    chunksNavigation:'scroll',
                    texts:{
                        count:"Showing page {page} out of {pages} pages",
                        first:"First",
                        last:"Last"
                    }
                },
                options: {
                    multiple: true,
                    multipleWidth: 70,
                    dropWidth: 580,
                },
                arrSortBy : [
                    { 'value' : 'price-desc', 'text':'Price: Highest First'},
                    { 'value' : 'price-asc', 'text':'Price: Lowest First'},
                    { 'value' : 'finance' , 'text': 'Finance Option Available' },
                    { 'value' : 'make-asc' , 'text': 'Make: A to Z' },
                    { 'value' : 'year-desc' , 'text': 'Year: Newest First' },
                    { 'value' : 'year-asc' , 'text': 'Year: Oldest First' },
                    { 'value' : 'mileage-asc' , 'text': 'Mileage: Lowest First' },
                    { 'value' : 'enginesize-desc' , 'text': 'Engine Size: Largest First' },
                    { 'value' : 'enginesize-asc' , 'text': 'Engine Size: Smallest First' },
                    { 'value' : 'fuelconsumption' , 'text': 'Fuel Economy: Best First' },
                    { 'value' : 'safetyrating' , 'text': 'Safety Rating: Best First' },
                    { 'value' : 'acceleration' , 'text': 'Acceleration: Fastest First' },
                    { 'value' : 'pullingpower' , 'text': 'Pulling Power: Strongest First' },
                    { 'value' : 'location', 'text': 'Location: A to Z'},
                    { 'value' : 'recent' , 'text': 'Most Recently Added'}
                ]
            }
        },
        mounted() {
            let tmpPage = this.$route.path.match(new RegExp("/page-[^/]*", 'gi'));
            tmpPage = tmpPage ? parseInt(tmpPage.toString().split('-')[1]) : 1;
            if(((tmpPage > this.maxPages) || (tmpPage > Math.ceil(this.total / this.perPage))) && tmpPage > 1){
                this.setRequestedPage(1);
                let frontendUri = this.$router.currentRoute.path.toString();
                //Remove Page
                frontendUri = frontendUri.replace(new RegExp("/page-[^/]*", 'gi'), "");
                this.$router.push(frontendUri);
            }
        },
        watch: {
            '$route.params': function () {
                this.clearPage();
            }
        },
        serverPrefetch () {
            let tmpPage = this.$route.path.match(new RegExp("/page-[^/]*", 'gi'));
            tmpPage = tmpPage ? parseInt(tmpPage.toString().split('-')[1]) : 1;
            if(((tmpPage > this.maxPages) || (tmpPage > Math.ceil(this.total / this.perPage))) && tmpPage > 1){
                const redirectUrl = this.$router.currentRoute.path.replace(new RegExp("/page-[^/]*", 'gi'), "/page-1");
                this.$ssrContext.redirect = redirectUrl;
                this.$ssrContext.paginatorRedirected = true;
                this.setCurrentPage(1);
                this.page = 1;
            }else{
                this.page = tmpPage;
            }
            this.setCurrentPage(this.page);

            if(this.seoData != null){
                this.$ssrContext.title = this.seoData.title;
                this.$ssrContext.description = this.seoData.description;
                this.$ssrContext.keywords = this.seoData.keywords;
                this.$ssrContext.robots = process.env.VUE_APP_ENV !== 'production' ? "noindex" : "all";
                this.$ssrContext.ldJson = JSON.stringify(this.seoData.ldJson);
            }
        },
        methods: {
            isMobile() {
                return isMobile
            },
            ...mapMutations([
                'setSearchMakeDesktop',
                'setSearchOrderBy',
                'setRequestedPage',
                'setManualSortBy',
                'setPagination',
                'setIsLoading',
                'setCurrentPage',
                'setCurrentPageName',
                'setUrlParams',
                'setUrl',
                'setTotal',
                'setTo',
                'setFrom',
                'setPerPage',
                'resetSearchVars'
            ]),
            ...mapActions([
                'orderByChangeAction',
                'performSearchAction',
                'updateUrlAction',
                'performSearchAction'
            ]),
            clearPage(){
                if(this.$router.currentRoute.fullPath == '/used-cars'){
                    this.$store.commit('setCurrentPage', 1);
                    this.$store.commit('setRequestedPage', 1);
                    this.$store.commit('ads/setError404', false);
                    this.fetchSearchResults(this.mySlug);
                }
            },
            resetFilters(){
                return this.$router.push('/used-cars');
            },
            setListMode(mode){
                this.$store.commit('setListViewMode',  mode);
            },
            subscribeEmailAlert(){
                let frontendUri = this.getFrontendUri();
                let backendUri = this.getBackendUri();

                if (this.validateEmail(this.email)) {
                    this.axios.post(process.env.VUE_APP_API_URL + '/api/email-alert', {
                        'email': this.email,
                        'frontend_uri': frontendUri,
                        'backend_uri': backendUri,
                    })
                        .then(response => {
                            alert('Thanks! \nWe have sent a verifcation email to you. Please click on the verification link in this email to activate the alert.');
                            console.log(response);
                        })
                        .catch(error => console.error(error.response.data));
                }else{
                    console.log("Invalid Email:", this.validateEmail(this.email));
                }
            },
            getFrontendUri(){

                let frontendUri = this.$router.currentRoute.path.toString();
                //Remove the /s-1 from the end of url
                frontendUri = frontendUri.replace("/s-1", "");
                //Remove Order By
                frontendUri = frontendUri.replace(new RegExp("/sortby-[^/]*", 'gi'), "");
                //Remove Page
                frontendUri = frontendUri.replace(new RegExp("/page-[^/]*", 'gi'), "");

                return frontendUri;
            },
            getBackendUri(){
                const url = new URL(this.$store.state.url.toString());
                let backendUri = url.search;
                //Remove SortBy
                backendUri = backendUri.replace(new RegExp("&sortBy=[^&]*", 'gi'), "");
                //Remove Page
                backendUri = backendUri.replace(new RegExp("&page=[^&]*", 'gi'), "");
                //Remove the initial ?&
                backendUri = backendUri.replace('?&','');

                return backendUri;

            },
            orderByChange($event) {
                // Sets the current filter
                this.setIsLoading(true);
                this.setSearchOrderBy($event.target.value);
                this.setRequestedPage(1);
                this.setCurrentPage(1);
                this.setManualSortBy(true);
                this.$route.query.page = null;
                this.search(true, $event.target.value);
                return false;
            },
            validateEmail(email) {
                const re = new RegExp("^(([^<>()\\[\\]\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");
                return re.test(String(email).toLowerCase());
            },
            pageChanged(){
                console.log("Max pages", this.maxPages);
                console.log("Current Page", this.page);
                this.setIsLoading(true);
                if(this.page > this.maxPages){
                    this.page = 1;
                }
                this.setRequestedPage(this.page);
                this.setCurrentPage(this.page);
                this.search(false, false);

            },
            loadLikes(){
                this.likes = localStorage.getItem('likes') != null ? JSON.parse(localStorage.getItem('likes')) : [];
            },
            search(skipUpdateUrl, sortParam){

                if (this.$route.name == "dealer") {
                    this.resetSearchVars();
                }

                // avoid triggering twice when changing sort by
                if(!skipUpdateUrl){
                    this.updateUrlAction();
                }

                let newQueryStringNew = '';
                let url = process.env.VUE_APP_API_URL +'/api/ad-elastic-filters?';
                let currentQueryString = this.$router.currentRoute.fullPath;
                let dealerSlug = '';

                if(this.$route.name  == "shortlist"){
                    let url = process.env.VUE_APP_API_URL +'/api/ad-elastic-filters?';
                    this.loadLikes();
                    url  += 'adIds=' + this.likes.toString() + '&page=' + this.currentPage;
                    this.setUrl(url);
                }else if(this.$route.name  == "dealer"){
                    dealerSlug = this.$router.currentRoute.params.dealerSlug.split('&')[0];
                    this.$store.commit('setUrlParams', {});
                    let pageParam = '';
                    let sortParam = '';


                    if(this.page > 1){
                        pageParam = '/page/' + this.page ;
                        url+='&page=' + this.page;
                    }
                    if(sortParam){
                        if(sortParam != 'price-desc'){
                            sortParam = '/sortby/' + sortParam;
                            url += '&sortby=' + sortParam;
                        }
                    }else{
                        if(this.$store.state.searchOrderBy && this.$store.state.searchOrderBy != 'price-desc'){
                            sortParam = '/sortby/' + this.$store.state.searchOrderBy;
                            url += '&sortby=' + this.$store.state.searchOrderBy;
                        }
                    }
                    newQueryStringNew = '/' + this.$router.currentRoute.name + '/' + dealerSlug + sortParam +  pageParam;
                    url += '&dealer=' + dealerSlug + '&' + Object.keys(this.$store.state.urlParams).map(key => key + '=' + this.$store.state.urlParams[key]).join('&');
                    newQueryStringNew = newQueryStringNew.endsWith("&") ? newQueryStringNew.substring(0, newQueryStringNew.length - 1) : newQueryStringNew;


                    url = url.endsWith("&") ? url.substring(0, url.length - 1) : url;
                    this.setUrl(url);
                    if(typeof document !== 'undefined'){
                        let elmnt = document.getElementsByClassName("dealerdetails");
                        elmnt[0].scrollIntoView();
                    }
                }else{
                    let params = this.$store.state.urlParams;
                    if(sortParam){
                        //Don't put price-desc on the url (default)
                        if(sortParam != 'price-desc'){
                            params.sortby = sortParam;
                        }else{
                            delete params.sortby;
                        }
                    }
                    newQueryStringNew = '/' + this.$router.currentRoute.name + '/' + Object.keys(params).map(key => key + '-' + this.$store.state.urlParams[key]).join('/');
                }

                if(newQueryStringNew != currentQueryString){
                    if(this.$route.name != 'shortlist' && this.$route.name != "dealer"){
                        return this.$router.push({path: newQueryStringNew});
                    }else{
                        // Search
                        this.fetchSearchResults();
                        this.$router.push({path: newQueryStringNew});
                    }
                }else{
                    // Search.
                    this.fetchSearchResults();
                }
            },
            fetchSearchResults() {
                if (this.$route.name == "dealer") {
                    return this.$store.dispatch('fetchSearchDealer', this.$store.state.url);
                } else {
                    let slug = this.$route.params.slug == null ? 'used-cars' : this.$route.params.slug;
                    return this.$store.dispatch('fetchSearchResults', slug);
                }
            },
            showEmailAlert(){
                return this.$store.state.showEmailAlert && this.$router.currentRoute.name === 'used-cars';
            },
            findMake(){
                let res = null;
                if(this.$store.state.searchMakeDesktop.length == 1 && this.$store.state.makes != null){
                    res = this.$store.state.makes.filter(search => search.id == this.$store.state.searchMakeDesktop[0]);
                    res = res[0] ? res[0].labelDesktop : '';
                }
                return res;
            },
            findModel(){
                let res = null;
                if(this.$store.state.searchModelDesktop.length == 1 && this.$store.state.makeModels != null){
                    res = this.$store.state.makeModels.filter(search => search.id == this.$store.state.searchModelDesktop[0]);
                    res = res[0] && typeof res[0].labelDesktop === 'string' ? res[0].labelDesktop.replace(/_/g, ' ') : '';
                }
                return res;
            },
        },

        computed: {
            ...mapState([
                         'resultsLoaded',
                         'url',
                         'searchResults',
                         'searchOrderBy',
                         'pagination',
                         'total',
                         'from',
                         'to',
                         'perPage',
                         'currentPage',
                         'currentPageName',
                         'showHideEmailAlert'
                     ]),
            page: {
                get() {
                    return parseInt(this.$store.state.currentPage);
                },
                set(value) {
                    this.$store.commit('setCurrentPage', value);
                }
            },
            maxPages(){
                return parseInt(process.env.VUE_APP_MAX_PAGES);
            },
            hideFor404(){
                return this.$router.currentRoute.name === 'vehicle-detail'
            },
            searchOrderBy:{
                get() {
                    let res = this.$store.state.searchOrderBy;
                    let params = this.$store.state.urlParams;
                    delete params.s;
                    delete params.sortby;
                    if(res == 'price-desc' && this.$store.state.manualSortBy == false && Object.keys(params).length > 0){
                        res = 'price-asc';
                    }
                    return res;
                },
                set(value) {
                    this.$store.commit('setSearchOrderBy', value);
                }
            },
            singleMakeModel(){
                let make = this.findMake();
                let model = this.findModel();
                if(model){
                    return model;
                }
                return make;
            },
            searchResultData: {
                cache: false,
                get (){
                    let res = null;
                    if (this.$route.name == "shortlist" || this.$route.name == "dealer") {
                        res = this.$store.state.searchResults[this.$store.state.url];
                    }else if(this.$route.name == "vehicle-detail"){
                        res = this.$store.state.searchResults[this.slug];
                    }else {
                        let slug = this.$route.params.slug == null ? 'used-cars' : this.$route.params.slug;
                        res = this.$store.state.searchResults[slug];
                    }
                    return res != null && res.hits != null ? res.hits.hits : null;
                }
            },
            seoData () {
                let res = null;

                if(this.$route.name  == "shortlist" || this.$route.name == "dealer"){
                    res = this.$store.state.searchResults[this.$store.state.url];
                }else{
                    let slug = this.$route.params.slug == null ? 'used-cars' : this.$route.params.slug;
                    res = this.$store.state.searchResults[slug];
                }
                return res!= null && res.seo != null ?  res.seo : null;
            },
            formattedTotal(){
                return numeral(this.total).format("0,000");
            },
            mainTitle(){
                return this.seoData ? this.seoData.main_title : 'Used Cars';
            },
            mainSubtitle(){
                let res = this.seoData ? this.seoData.main_subtitle : 'Used Cars';
                return res;
            }
        }

    }
</script>

<style scoped>

</style>
