<template>
    <div  v-bind:class="{ 'carlistings__car': $store.state.listViewMode == 'list', 'carlistings__car carlistings__car--cardview': $store.state.listViewMode == 'card' }">
        <div class="carlistings__car_cardview_image aspect-ratio" style="--aspect-ratio-w: 4; --aspect-ratio-h: 3;">
            <router-link :to="{path: getLink}" data-class="opengallery">
<!--                <v-lazy-image   style="max-width:100%" data-aspect="0.75" v-bind:src="mainImageUrl" :src-placeholder="placeholderLazyImg"  data-class="opengallery" data-gallery-index="0" v-bind:alt="formattedLinkCarAlt" v-bind:title="formattedLinkCarTitle" />-->
                <img :src="mainImageUrl" width="257" height="193" :alt="formattedLinkCarAlt" :title="formattedLinkCarTitle" data-class="opengallery" data-gallery-index="0" >
            </router-link>
            <div class="carlistings__car_cardview_gallery">
                <router-link :to="{path: getLink}" data-class="opengallery">{{ imageCount }}</router-link>
            </div>
        </div>
        <div class="carlistings__car_cardviewtop">
            <div class="carlistings__car_cost">
                <div class="carlistings__car_price">{{ formattedCarPrice }}</div>
                <div class="carlistings__car_repayments">{{ formattedMonthlyPrice }}</div>
            </div>
            <div class="carlistings__car_like">
                <div v-on:click.stop="likeAdd(car.ad_id)" v-bind:class="{ 'likebutton': !liked, 'likebutton--liked': liked }"></div>
            </div>
        </div>
        <div class="carlistings__car_top">
            <div class="carlistings__car_title">
                <h2><router-link :to="{path: getLink}">{{ car.reg_year }} {{ car.make }} {{ car.model }}</router-link></h2>
                <h3>{{ formattedEngineSize }} {{ car.variant }} {{ car.doors }}dr</h3>
            </div>
            <div class="carlistings__car_like">
                <div v-on:click.stop="likeAdd(car.ad_id)"  v-bind:class="{ 'likebutton': !liked, 'likebutton--liked': liked }"> </div>
            </div>
        </div>
        <div class="carlistings__car_bottom">
            <div class="carlistings__car_images">
                <div class="carlistings__car_mainimage aspect-ratio" style="--aspect-ratio-w: 4; --aspect-ratio-h: 3;"  data-id="opengallery">
                    <router-link :to="{path: getLink}">
<!--                        <v-lazy-image style="max-width:100%" data-aspect="0.75" v-bind:src="mainImageUrl" :src-placeholder="placeholderLazyImg" data-class="opengallery" data-gallery-index="0" v-bind:alt="formattedLinkCarAlt" v-bind:title="formattedLinkCarTitle" />-->
                        <img :src="mainImageUrl"  :alt="formattedLinkCarAlt" :title="formattedLinkCarTitle" data-class="opengallery" data-gallery-index="0" width="257" height="193" >
                    </router-link>
                    <div class="carlistings__car_icons">
                        <div class="carlistings__car_icon" v-if="imageCount > 0">
                            <div class="icon icon--camera"><router-link :to="{path: getLink}" data-class="opengallery" alt="Camera icon link">{{ imageCount }}</router-link></div>
                        </div>
                        <div class="carlistings__car_icon" v-if="car.youtube_id != null">
                            <div class="icon icon--video"><router-link :to="{path: getLink}" data-class="opevideo" data-video-url="" alt="Video camera icon link" class="hasVideo">has videos</router-link></div>
                        </div>
                    </div>
                </div>
                <div class="carlistings__car_thumbnails">
                    <div class="carlistings__car_thumbnailrow">
                        <div class="carlistings__car_thumbnail aspect-ratio" style="--aspect-ratio-w: 4; --aspect-ratio-h: 3;" data-id="opengallery">
                            <router-link :to="{path: getLink}">
<!--                                <v-lazy-image style="max-width:100%" data-aspect="0.75" v-bind:src="image1Url" :src-placeholder="placeholderLazyImg" v-bind:alt="formattedLinkCarAlt" v-bind:title="formattedLinkCarTitle"  />-->
                                <img :src="image1Url" width="125" height="94" :alt="formattedLinkCarAlt" :title="formattedLinkCarTitle" >
                            </router-link>
                        </div>
                        <div class="carlistings__car_thumbnail aspect-ratio" style="--aspect-ratio-w: 4; --aspect-ratio-h: 3;" data-id="opengallery">
                            <router-link :to="{path: getLink}">
<!--                                <v-lazy-image style="max-width:100%" data-aspect="0.75" v-bind:src="image2Url" :src-placeholder="placeholderLazyImg" v-bind:alt="formattedLinkCarAlt" v-bind:title="formattedLinkCarTitle"  />-->
                                <img :src="image2Url" width="125" height="94" :alt="formattedLinkCarAlt" :title="formattedLinkCarTitle" >
                            </router-link>
                        </div>
                    </div>
                    <div class="carlistings__car_thumbnailrow">
                        <div class="carlistings__car_thumbnail aspect-ratio" style="--aspect-ratio-w: 4; --aspect-ratio-h: 3;" data-id="opengallery">
                            <router-link :to="{path: getLink}">
<!--                                <v-lazy-image v-bind:src="image3Url" :src-placeholder="placeholderLazyImg" v-bind:alt="formattedLinkCarAlt" v-bind:title="formattedLinkCarTitle"  />-->
                                <img :src="image3Url" width="125" height="94" :alt="formattedLinkCarAlt" :title="formattedLinkCarTitle" >
                            </router-link>
                        </div>
                        <div class="carlistings__car_thumbnail aspect-ratio" style="--aspect-ratio-w: 4; --aspect-ratio-h: 3;" data-id="opengallery">
                            <router-link :to="{path: getLink}">
<!--                                <v-lazy-image v-bind:src="image4Url" :src-placeholder="placeholderLazyImg" v-bind:alt="formattedLinkCarAlt" v-bind:title="formattedLinkCarTitle"  />-->
                                <img :src="image4Url" width="125" height="94" :alt="formattedLinkCarAlt" :title="formattedLinkCarTitle" >
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carlistings__car_details">
                <div class="carlistings__car_top carlistings__car_top--desktop">
                    <div class="carlistings__car_title">
                        <h2>
                            <router-link :to="{path: getLink}">
                            {{ formattedDesktopTitle }}
                            </router-link>
                        </h2>
                    </div>
                    <router-link :to="{path: getLink}">
                        <div class="carlistings__car_cost carlistings__car_cost--desktop">
                            <div class="carlistings__car_price">{{ formattedCarPrice }}</div>
                            <div class="carlistings__car_repayments">{{ formattedMonthlyPrice }}</div>
                        </div>
                    </router-link>
                </div>
                <h3 class="updated-at--desktop">Updated {{ formattedUpdatedAtDate }}</h3>
                <div class="carlistings__car_desc">
                    <p>{{ formattedDesktopDescription }}</p>
                    <div class="carlistings__car_like carlistings__car_like--desktop">
                        <div  v-on:click.stop="likeAdd(car.ad_id)"  v-bind:class="{ 'likebutton': !liked, 'likebutton--liked': liked }"></div>
                    </div>
                </div>
                <div class="carlistings__car_features">
                    <router-link :to="{path: getLink}">
                        <ul :class="[ checkSafari ? 'safari' : '' ]">
                            <li>{{ car.reg_year }}{{ formattedRegPrefix }}</li>
                            <li><span v-if="formattedFuelType !== 'Electric'">{{ formattedEngineSize }}</span> {{ formattedFuelType }}</li>
                            <li>{{ formattedMileage }} mi</li>
                            <li>{{ formattedOwners }} Owner{{car.owners == 1  ? '' : 's'}}</li>
                            <li>{{ formattedTransmissionType | formataggdash }}</li>
                            <li>NCT {{ formattedNCT }}</li>
                        </ul>
                    </router-link>
                </div>
                <div class="carlistings__car_foot">
                    <!--<div class="carlistings__car_dealerrating">-->
                        <!--<small>Dealer:</small>-->
                        <!--<div class="stars">-->
                            <!--<span v-for="(star, idx) in numberStars" v-bind:key="idx"></span>-->
                            <!--<span class="stars__empty" v-for="(star, idy) in (5-numberStars)" v-bind:key="idy+100"></span>-->
                        <!--</div>-->
                    <!--</div>-->
                        <div class="carlistings__car_location">
                            <div class="carlistings__car_location_pin">
                                <router-link :to="{path: getLink}">
                                    {{ getRegion }} <small> Updated {{ formattedUpdatedAtDate }}</small>
                                </router-link>
                            </div>
                        </div>
                        <div class="carlistings__car_cardviewlocation">
                            <router-link :to="{path: getLink}">
                                <div class="carlistings__car_location_pin">{{ getRegion }}</div>
                                <small>Updated {{ formattedUpdatedAtDate }}</small>
                            </router-link>
                        </div>
                        <router-link :to="{path: getLink}">
                            <div class="carlistings__car_cost">
                                <div class="carlistings__car_repayments">{{ formattedMonthlyPrice }}</div>
                                <div class="carlistings__car_price">{{ formattedCarPrice }}</div>
                            </div>
                        </router-link>
                        <div class="carlistings__cta">
                            <router-link :to="{path: getLink}">Car Details</router-link>
                        </div>
                        <div class="carlistings__cardviewcta">
                            <router-link :to="{path: getLink}">Car Details</router-link>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    // import VLazyImage from "v-lazy-image"
    var moment = require('moment')
    var numeral = require('numeral')

    export default {
        name: "CarListingsCar",
        props: ['car', 'index'],
        components:{
            // VLazyImage
        },
        data: function() {
            return {
                likes: [],
                liked:false
            }
        },
        mounted(){
            this.loadLikes();
            this.checkLickedAd();
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            formatagg: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.replace(/_/g, ' ')
            },
            formataggdash: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.replace(/_/g, '-')
            }
        },
        methods: {
            getLinkExtraParams(link){
                let queryString = '';
                if(this.searchFinanceDeposit > 0){
                    queryString = queryString + '&deposit=' + this.searchFinanceDeposit;

                    if(this.searchMonthlyPricesFrom > 0 ){
                        queryString = queryString + '&priceFrom=' + this.searchMonthlyPricesFrom;
                    }

                    if(this.searchMonthlyPricesTo > 0 ){
                        queryString = queryString + '&priceTo=' + this.searchMonthlyPricesTo;
                    }

                    if(queryString.length > 0 && queryString.charAt(0) == '&'){
                        queryString = '?' + queryString.substring(1);
                    }

                }

                return link + queryString;
            },
            stringToSlug (str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
                let to   = "aaaaeeeeiiiioooouuuunc------";
                for (let i=0, l=from.length ; i<l ; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                    .replace(/\s+/g, '+') // collapse whitespace and replace by -
                    .replace(/-+/g, '+'); // collapse dashes

                return str;
            },
            loadLikes(){
                this.likes = localStorage.getItem('likes') != null ? JSON.parse(localStorage.getItem('likes')) : [];
            },
            likeAdd(adId){
                this.loadLikes();

                if(!this.likes.includes(adId)){
                    this.likes.push(adId);
                }else{
                    this.likes = this.likes.filter(element => element != adId);
                }
                localStorage.setItem('likes', JSON.stringify(this.likes));
                this.checkLickedAd();
            },
            checkLickedAd(){
                if(this.likes.includes(this.car.ad_id)){
                    this.liked = true;
                }else{
                    this.liked = false;
                }
            },
            findMinMonthlyPayment(arr) {
                let min = arr[0].monthly_payments;
                for (let i = 1, len=arr.length; i < len; i++) {
                    let v = arr[i].monthly_payments;
                    min = (v < min) ? v : min;
                }
                return min;
            },
            findMaxMonthlyPayment(arr) {
                let max = arr[0].monthly_payments;
                for (let i = 1, len=arr.length; i < len; i++) {
                    let v = arr[i].monthly_payments;
                    max = (v > max) ? v : max;
                }
                return max;
            },
            findCloserDepositLower(arr, deposit) {
                let min = 0;

                if(this.searchMonthlyPricesFrom != null && this.searchMonthlyPricesTo == null){
                    for (let i = 0, len=arr.length; i < len; i++) {
                            if(arr[i].deposit_and_trade_in <= deposit && arr[i].monthly_payments >= this.searchMonthlyPricesFrom && arr[i].is_default_term == 1){
                            min = arr[i].monthly_payments;
                        }
                    }
                }else if(this.searchMonthlyPricesFrom == null && this.searchMonthlyPricesTo != null){
                    for (let i = 0, len=arr.length; i < len; i++) {
                        if(arr[i].deposit_and_trade_in <= deposit && arr[i].monthly_payments <= this.searchMonthlyPricesTo && arr[i].is_default_term == 1){
                            min = arr[i].monthly_payments;
                        }
                    }
                }else if(this.searchMonthlyPricesFrom != null && this.searchMonthlyPricesTo != null){
                    for (let i = 0, len=arr.length; i < len; i++) {
                        if(arr[i].deposit_and_trade_in <= deposit && arr[i].monthly_payments >= this.searchMonthlyPricesFrom && arr[i].monthly_payments <= this.searchMonthlyPricesTo && arr[i].is_default_term == 1){
                            min = arr[i].monthly_payments;
                        }
                    }
                }else{
                    for (let i = 0, len=arr.length; i < len; i++) {
                        if(arr[i].deposit_and_trade_in <= deposit && arr[i].is_default_term == 1){
                            min = arr[i].monthly_payments;
                        }
                    }
                }

                if(min==0){
                    min = this.findCloserDepositLowerNotDefault(arr, deposit);
                }
                return min;
            },
            findCloserDepositLowerNotDefault(arr, deposit) {
                let min = 0;

                if(this.searchMonthlyPricesFrom != null && this.searchMonthlyPricesTo == null){
                    for (let i = 0, len=arr.length; i < len; i++) {
                        if(arr[i].deposit_and_trade_in <= deposit && arr[i].monthly_payments >= this.searchMonthlyPricesFrom && arr[i].is_default_term == 0){
                            min = arr[i].monthly_payments;
                        }
                    }
                }else if(this.searchMonthlyPricesFrom == null && this.searchMonthlyPricesTo != null){
                    for (let i = 0, len=arr.length; i < len; i++) {
                        if(arr[i].deposit_and_trade_in <= deposit && arr[i].monthly_payments <= this.searchMonthlyPricesTo && arr[i].is_default_term == 0){
                            min = arr[i].monthly_payments;
                        }
                    }
                }else if(this.searchMonthlyPricesFrom != null && this.searchMonthlyPricesTo != null){
                    for (let i = 0, len=arr.length; i < len; i++) {
                        if(arr[i].deposit_and_trade_in <= deposit && arr[i].monthly_payments >= this.searchMonthlyPricesFrom && arr[i].monthly_payments <= this.searchMonthlyPricesTo && arr[i].is_default_term == 0){
                            min = arr[i].monthly_payments;
                        }
                    }
                }else{
                    for (let i = 0, len=arr.length; i < len; i++) {
                        if(arr[i].deposit_and_trade_in <= deposit && arr[i].is_default_term == 0){
                            min = arr[i].monthly_payments;
                        }
                    }
                }
                return min;
            },
        },

        computed: {
            ...mapState([
                'searchFinanceDeposit',
                'searchMonthlyPricesFrom',
                'searchMonthlyPricesTo',
            ]),
            hideFor404(){
                return this.$router.currentRoute.name === 'vehicle-detail'
            },
            checkSafari(){
                if(typeof navigator == 'undefined'){
                    return false;
                }
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') != -1) {
                    if (ua.indexOf('chrome') > -1) {
                        // Chrome
                    } else {
                        return true
                    }
                }
                return false;
            },
            getLink(){
                let link = '/used-cars/makes-' + this.stringToSlug(this.car.make) + '/models-' + this.stringToSlug(this.car.model) + '/r/' + this.car.slug;
                return this.getLinkExtraParams(link);
            },
            numberStars() {
                return Math.round(this.car.numberStars);
            },
            formattedDesktopTitle(){
                let text = this.car.reg_year + ' ' + this.car.make + ' ' + this.car.model + ' ' + this.car.variant;
                    // text = text.length > 65 ? text.slice(0, 65) + ' ...' : text;
                return text;
            },
            formattedDesktopDescription(){
                let text = this.car.description;
                // text = text && text.length > 150 ? text.slice(0, 150) + ' ...' : text;
                return text;
            },
            formattedOwners(){
                return this.car.owners == null ? 'N/A' : this.car.owners;
            },
            formattedFuelType(){
                let ft = this.car.fuel_type;
                if(ft != null){
                    return ft.replace(/^hybrid.*/i, "Hybrid");
                }else{
                    return '';
                }
            },
            formattedTransmissionType(){
                return this.car.transmission_type;
            },
            formattedLinkCarTitle() {
                return  this.car.reg_year  + ' ' + this.car.make + ' ' + this.car.model + ' ' + this.car.variant;
            },
            formattedLinkCarAlt() {
                return  "Image for " + this.car.reg_year  + ' ' + this.car.make + ' ' + this.car.model + ' ' + this.car.variant;
            },

            formattedUpdatedAtDate() {
                return this.car.updated_at != null ? moment(this.car.updated_at).format('DD/MM/YYYY') : 'N/A';
            },

            formattedEngineSize() {
                return numeral(this.car.engine_cc / 1000).format('0.0');
            },

            formattedMileage() {
                return numeral(this.car.mileage).format('0,0');
            },

            formattedNCT() {
                return this.car.test_due_date != null ? moment(this.car.test_due_date).format('MM/YY') : 'N/A';
            },

            formattedRegPrefix() {
                return this.car.reg_prefix != null ? ' (' + this.car.reg_prefix + ')' : '';
            },

            formattedCarPrice() {
                if(this.car.price <= 10){
                    return "POA";
                }else{
                    return "€" + numeral(this.car.price).format('0,0');
                }
            },

            formattedMonthlyPrice() {
                let res = null;
                if(this.searchFinanceDeposit != null){

                    if(this.searchMonthlyPricesFrom != null && this.searchMonthlyPricesTo == null){
                        res = this.car.finance_options.filter(element => element.deposit_and_trade_in == this.searchFinanceDeposit && element.monthly_payments >= this.searchMonthlyPricesFrom && element.is_default_term == 1);
                        if(res != null && res.length > 0){
                            res = res[0].monthly_payments;
                        }
                    }else if(this.searchMonthlyPricesFrom == null && this.searchMonthlyPricesTo != null){
                        res = this.car.finance_options.filter(element => element.deposit_and_trade_in == this.searchFinanceDeposit && element.monthly_payments <= this.searchMonthlyPricesTo  && element.is_default_term == 1);
                        if(res != null && res.length > 0){
                            res = res[0].monthly_payments;
                        }
                    }else if(this.searchMonthlyPricesFrom != null && this.searchMonthlyPricesTo != null){
                        res = this.car.finance_options.filter(element => element.deposit_and_trade_in == this.searchFinanceDeposit && element.monthly_payments >= this.searchMonthlyPricesFrom && element.monthly_payments <= this.searchMonthlyPricesTo  && element.is_default_term == 1);
                        if(res != null && res.length > 0){
                            res = res[0].monthly_payments;
                        }
                    }else{
                        res = this.car.finance_options.filter(element => element.deposit_and_trade_in == this.searchFinanceDeposit  && element.is_default_term == 1);
                        if(res != null && res.length > 0){
                            res = res[0].monthly_payments;
                        }
                    }

                    if(res.length == 0){
                        res = this.findCloserDepositLower(this.car.finance_options, this.searchFinanceDeposit);
                    }
                }
                /*
                if(this.searchMonthlyPricesFrom != null && this.searchMonthlyPricesTo == null){
                    res = this.car.finance_options.filter(element => element.monthly_payments >= this.searchMonthlyPricesFrom);
                    if(res != null && res.length > 0){
                        res = this.findMinMonthlyPayment(res);
                    }
                }

               else if(this.searchMonthlyPricesFrom == null && this.searchMonthlyPricesTo != null){
                   res = this.car.finance_options.filter(element => element.monthly_payments <= this.searchMonthlyPricesTo);
                   if(res != null && res.length > 0){
                       res = this.findMaxMonthlyPayment(res);
                   }
               }
               else if(this.searchMonthlyPricesFrom != null && this.searchMonthlyPricesTo != null){
                   res = this.car.finance_options.filter(element => element.monthly_payments >= this.searchMonthlyPricesFrom && element.monthly_payments <= this.searchMonthlyPricesTo);
                   if(res != null && res.length > 0){
                       res = this.findMaxMonthlyPayment(res);
                   }
               }
               */
                else {
                    res = this.car.from_monthly;
                }

                return  res != null && res > 0 ? 'From €' + numeral(res).format('0,0') + ' pm' : 'From n/a pm';
            },
            getRegion(){
                return (this.car.dealer != null && this.car.location != null && this.car.location.length > 0) ? this.car.location[0].region : '';
            },
            imageCount() {
                return this.car.image_count;
            },

            placeholderLazyImg(){
                return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
            },
            mainImageUrl() {
                return this.car.images.length > 0 && this.car.images[0].primaryURL.xl != null ? this.car.images[0].primaryURL.xl : require('../../assets/img/photos/missing/large.jpg');
            },

            image1Url() {
                return this.car.images.length > 1 && this.car.images[1].primaryURL.large != null ? this.car.images[1].primaryURL.large : require('../../assets/img/photos/missing/medium.jpg');
            },

            image2Url() {
                return this.car.images.length > 2 && this.car.images[2].primaryURL.large != null ? this.car.images[2].primaryURL.large : require('../../assets/img/photos/missing/medium.jpg');
            },

            image3Url() {
                return this.car.images.length > 3 && this.car.images[3].primaryURL.large != null ? this.car.images[3].primaryURL.large : require('../../assets/img/photos/missing/medium.jpg');
            },

            image4Url() {
                return this.car.images.length > 4 && this.car.images[4].primaryURL.large != null ? this.car.images[4].primaryURL.large : require('../../assets/img/photos/missing/medium.jpg');
            },
        }
    }
</script>

<style scoped>
</style>
