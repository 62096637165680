<template>
    <div class='VuePagination' :class='props.theme.wrapper'>
        <nav :class='props.theme.nav'>

            <ul v-show="props.showPagination" :class="props.theme.list">

<!--                <li v-if="props.hasEdgeNav" :class='props.theme.firstPage' @click="props.setFirstPage">-->
<!--                    <div v-bind="{...props.aProps,...props.firstPageProps}"><a :href="getLink(1)">{{props.texts.first}}</a></div>-->
<!--                </li>-->

                <li v-if="props.hasChunksNav" :class='props.theme.prevChunk' @click="props.setPrevChunk">
                    <div v-bind="{...props.aProps, ...props.prevChunkProps}">{{props.texts.prevChunk}}</div>
                </li>

                <li :class="props.theme.prev" @click="props.setPrevPage">
                    <div v-bind="{...props.aProps,...props.prevProps}"><a :href="getLink(this.$store.state.currentPage - 1)" class="link-previous-page">{{props.texts.prevPage}}</a></div>
                </li>

                <li v-for="page in props.pages" :key="page" :class="props.pageClasses(page)"
                    v-on="props.pageEvents(page)">
                    <div v-bind="props.aProps" :class="props.theme.link"><a :href="getLink(page)">{{page}}</a></div>
                </li>

                <li :class="props.theme.next" @click="props.setNextPage">
                    <div v-bind="{...props.aProps, ...props.nextProps}"><a :href="getLink(this.$store.state.currentPage + 1)" class="link-next-page">{{props.texts.nextPage}}</a></div>
                </li>

                <li v-if="props.hasChunksNav" :class='props.theme.nextChunk' @click="props.setNextChunk">
                    <div v-bind="{...props.aProps, ...props.nextChunkProps}">{{props.texts.nextChunk}}</div>
                </li>

<!--                <li v-if="props.hasEdgeNav" :class="props.theme.lastPage" @click="props.setLastPage">-->
<!--                    <div v-bind="{...props.aProps, ...props.lastPageProps}"><a :href="getLink(props.totalPages)">{{props.texts.last}}</a></div>-->
<!--                </li>-->

            </ul>

            <p v-if="this.$store.state.currentPage < 30" v-show="props.hasRecords" :class='props.theme.count'>{{props.count}}</p>
            <p v-else v-show="props.hasRecords" :class='props.theme.count'>Too many results to show. Please try refining your search using the filters.</p>

        </nav>
    </div>

</template>

<script>
export default {
    name: 'TerrificPagination',
    props: ['props'],
    mounted(){
        // Prevent link propagation on the paginator
        document.querySelectorAll('.VuePagination a').forEach((e) => {
          e.addEventListener("click", function(e) {
                e.preventDefault();
            });
        })
    },
    methods:{
        getLink(page){
            if(this.$router.currentRoute.name == 'dealer'){

                if(this.$router.currentRoute.path.match(/page\/(\d+)/)){
                    return this.$router.currentRoute.path
                        .replace(/page\/(\d+)/,'page/'+page);
                }else{
                    return this.$router.currentRoute.path + '/page/' + page;
                }

            }else{
                return this.$router.currentRoute.path
                    .replace(/page-(\d+)/,'page-'+page);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.VuePagination__pagination-item{
    .page-link{
        a{
            color:  #657786;
        }
    }
}

.VuePagination__pagination-item.active{
    .page-link{
        a{
            color:  white;
        }
    }
}
</style>
