<template>
    <div v-if="searchResultData">
    <!-- Model Box-->
    <div class="carlistings__bymake" v-show="showModels">
        <ul>
            <li v-for="(model, idx) in makeModels"  v-bind:key="idx">
                <router-link :to="{path: '/used-cars/makes-' + getMake + '/models-' + model.id}">
                    {{ getFormattedMakeModel(model.labelDesktop) }} ({{ model.docCount }})
                </router-link>
            </li>
        </ul>
    </div>
    <!-- End of Model Box -->

    <!-- Year Box-->
    <div class="carlistings__bymake" v-show="showYears">
        <ul>
            <li v-for="(year, idx) in yearsFrom"  v-bind:key="idx">
                <router-link :to="{path: '/used-cars/makes-' + getMake + '/models-' + getModel + '/yearfrom-' + year.id + '/yearto-' + year.id }">
                    {{year.label}} ({{ year.docCount }})
                </router-link>
            </li>
        </ul>
    </div>
    <!-- End of Year Box -->
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "RelatedSearch",
        computed: {
            ...mapState([
                'makeModels',
                'years',
                'yearsFrom',
                'yearsTo',
                'searchMakeDesktop',
                'searchModelDesktop',
                'searchYearFrom',
                'searchYearTo',
            ]),
            searchResultData () {
                this.parseUrl();
                this.updateParams();
                return this.$store.state.searchResults[this.slug];
            },
            getMake() {
                return this.searchMakeDesktop.length > 0 ? this.searchMakeDesktop[0] : null;
            },
            getModel() {
                return this.searchModelDesktop.length > 0 ? this.searchModelDesktop[0] : null;
            },
            showModels() {
                return this.makeModels != null && this.searchMakeDesktop.length == 1 && this.searchModelDesktop.length == 0 && this.s == null;
            },
            showYears() {
                return (
                        this.makeModels != null &&
                        this.searchMakeDesktop.length == 1 &&
                        this.searchModelDesktop.length == 1 &&
                        this.searchYearFrom == null &&
                        this.searchYearTo == null &&
                        this.s == null
                );
            }
        },
        data () {
            return {
                isContentFetched: false,
                slug: null,
                s: null,
                objParams: {}
            }
        },
        mounted(){
            this.slug = this.$router.currentRoute.fullPath.replace('/used-cars/', '');
            this.parseUrl();
            this.updateParams();
        },
        methods: {
            getFormattedMakeModel(makeModel){
                if(typeof makeModel != 'string'){
                    return '';
                }

                return makeModel
                    .replace('_', ' ')
                    .replace('Mercedes Benz', 'Mercedes-Benz');
            },
            parseUrl(){
                this.slug = this.$router.currentRoute.fullPath.replace('/used-cars/', '');
                this.urlParams = this.slug;
                let objParams = {};
                let arrUrlParams = [];
                if (this.urlParams != null) {
                    arrUrlParams = this.urlParams.split('/');
                    arrUrlParams.forEach((val) => {
                        let arr = val.split('-');
                        let values = val.split(/-(.+)/)[1]
                        objParams[arr[0]] = values;
                    });
                }
                this.objParams = objParams;
            },
            updateParams(){
                if(this.objParams.s != null){
                    this.s = this.objParams.s;
                }
            }
        }
    }
</script>

<style scoped>

</style>
