<template>
    <div class="search__selected_filters" v-if="showFilters">

        <!-- Makes-->
        <div class="filter-on" v-for="i in getMakes">
            <div class="filter">{{ i[0].val }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Models-->
        <div class="filter-on" v-for="i in getModels">
            <div class="filter">{{ i[0].val }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Year From -->
        <div class="filter-on" v-if="getYearFrom">
            <div class="filter">From: {{ getYearFrom }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Year To -->
        <div class="filter-on" v-if="getYearTo">
            <div class="filter">To: {{ getYearTo }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Price From -->
        <div class="filter-on" v-if="getPriceFrom">
            <div class="filter">From: €{{ getPriceFrom }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Price To -->
        <div class="filter-on" v-if="getPriceTo">
            <div class="filter">To: €{{ getPriceTo }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Deposit -->
        <div class="filter-on" v-if="getDeposit">
            <div class="filter">Dep. €{{ getDeposit }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Monthly Price From -->
        <div class="filter-on" v-if="getMonthlyPriceFrom">
            <div class="filter">From: €{{ getMonthlyPriceFrom }}<span> p/m</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Monthly Price To -->
        <div class="filter-on" v-if="getMonthlyPriceTo">
            <div class="filter">To: €{{ getMonthlyPriceTo }}<span> p/m</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Locations-->
        <div class="filter-on" v-for="i in getLocations">
            <div class="filter">{{ i[0].val }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Mileage From -->
        <div class="filter-on" v-if="getMileageFrom">
            <div class="filter">{{ getMileageFrom }}<span> mi</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- kilometres To -->
        <div class="filter-on" v-if="getMileageTo">
            <div class="filter">{{ getMileageTo }} <span> km</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Kilometres From -->
        <div class="filter-on" v-if="getKilometresFrom">
            <div class="filter">{{ getKilometresFrom }}<span> km</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Kilometres To -->
        <div class="filter-on" v-if="getKilometresTo">
            <div class="filter">{{ getKilometresTo }} <span> mi</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Engine Size From -->
        <div class="filter-on" v-if="getEngineSizeFrom">
            <div class="filter">{{ getEngineSizeFrom }}<span> L</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Engine Size To -->
        <div class="filter-on" v-if="getEngineSizeTo">
            <div class="filter">{{ getEngineSizeTo }} <span> L</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Gearbox / Transmission Type-->
        <div class="filter-on" v-for="i in getTransmissonTypes">
            <div class="filter">{{ i[0].val }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Fuel Type -->
        <div class="filter-on" v-for="i in getFuelTypes">
            <div class="filter">{{ i[0].val }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Body Type -->
        <div class="filter-on" v-for="i in getBodyTypes">
            <div class="filter">{{ i[0].val }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Colour -->
        <div class="filter-on" v-for="i in getColours">
            <div class="filter">{{ i[0].val }}</div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Seats -->
        <div class="filter-on" v-for="i in getSeats">
            <div class="filter">{{ i[0].val }}<span> Seats</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Doors -->
        <div class="filter-on" v-for="i in getDoors">
            <div class="filter">{{ i[0].val }}<span> Doors</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Safety Rating -->
        <div class="filter-on" v-for="i in getSafetyRatings">
            <div class="filter">{{i[0].val != 5 ? i[0].val + '+' : '5'}}<span> Star</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Fuel Consumption -->
        <div class="filter-on" v-for="i in getFuelConsumption">
            <div class="filter">{{i[0].val}}+<span> mpg</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Environmental -->
        <div class="filter-on" v-for="i in getEnvironmental">
            <div class="filter">{{i[0].val != 5 ? i[0].val + '+' : '5'}}<span> Star</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Engine Power -->
        <div class="filter-on" v-for="i in getEnginePowers">
            <div class="filter">{{i[0].val.replace('+', '-')}}<span> BHP</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Acceleration -->
        <div class="filter-on" v-for="i in getAccelerations">
            <div class="filter">{{i[0].val.replace('+', '-') != 12 ? i[0].val.replace('+', '-') + 's' : '12s+'}}<span> (0-60mph)</span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

        <!-- Freetext -->
        <div class="filter-on" v-if="getFreeText">
            <div class="filter">{{ getFreeText }}<span></span></div>
            <button type="button" aria-label="Exclude filter" @click="removeFilter($event.target)">
                <span aria-hidden="true" class="andes-tag__icon-container">
                    <svg class="andes-tag__close-icon andes-tag__svg-fill" width="16" height="16" viewBox="0 0 16 16">
                        <path transform="translate(3 3)" fill-opacity="0.45" d="M8.594.552l.855.842L5.87 5.022 9.45 8.6l-.849.848-3.572-3.572-3.521 3.572-.855-.842L4.18 5.028.552 1.4l.849-.848 3.621 3.62L8.594.553z"></path>
                    </svg>
                </span>
            </button>
        </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "SelectedFilters",
        data: function() {
            return {
                slug: false,
            }
        },
        computed: {
            getMakes() {
                return this.$store.state.searchMakeDesktop.map((item) => {
                    let response = {'type': 'make', 'val' :item.replace(/\+/g, " ")};
                    return [response];
                });
            },
            getModels() {
                return this.$store.state.searchModelDesktop.map((item) => {
                    let response = {'type': 'model', 'val' :item.replace(/\+/g, " ")};
                    return [response];
                });
            },
            getLocations() {
                return this.$store.state.searchLocations.map((item) => {
                    let response = {'type': 'location', 'val' :item};
                    return [response];
                });
            },
            getTransmissonTypes() {
                return this.$store.state.searchTransmissionType.map((item) => {
                    let response = {'type': 'gearbox', 'val' :item};
                    return [response];
                });
            },
            getFuelTypes() {
                return this.$store.state.searchFuelType.map((item) => {
                    let response = {'type': 'fuel', 'val' :item.replace(/\+/g, " ")};
                    return [response];
                });
            },
            getBodyTypes() {
                return this.$store.state.searchBodyType.map((item) => {
                    let response = {'type': 'body', 'val' :item.replace('+', ' ')};
                    return [response];
                });
            },
            getColours(){
                return this.$store.state.searchColour.map((item) => {
                    let response = {'type': 'colour', 'val' :item.replace('+', ' ')};
                    return [response];
                });
            },
            getSeats(){
                return this.$store.state.searchSeats.map((item) => {
                    let response = {'type': 'seats', 'val' :item};
                    return [response];
                });
            },
            getDoors(){
                return this.$store.state.searchDoors.map((item) => {
                    let response = {'type': 'doors', 'val' :item};
                    return [response];
                });
            },
            getSafetyRatings(){
                return this.$store.state.searchSafetyRating.map((item) => {
                    let response = {'type': 'safety', 'val' :item};
                    return [response];
                });
            },
            getFuelConsumption(){
                return this.$store.state.searchFuelConsumption.map((item) => {
                    let response = {'type': 'fuelConsumption', 'val' :item};
                    return [response];
                });
            },
            getEnvironmental(){
                return this.$store.state.searchEnvironmental.map((item) => {
                    let response = {'type': 'environmental', 'val' :item};
                    return [response];
                });
            },
            getEnginePowers(){
                return this.$store.state.searchEnginePower.map((item) => {
                    let response = {'type': 'enginePower', 'val' :item};
                    return [response];
                });
            },
            getAccelerations(){
                return this.$store.state.searchAcceleration.map((item) => {
                    let response = {'type': 'acceleration', 'val' :item};
                    return [response];
                });
            },
            getYearFrom(){
                return this.$store.state.searchYearFrom;
            },
            getYearTo(){
                return this.$store.state.searchYearTo;
            },
            getPriceFrom(){
                return this.$store.state.searchPriceFrom;
            },
            getPriceTo(){
                return this.$store.state.searchPriceTo;
            },
            getDeposit(){
                return this.$store.state.searchFinanceDeposit;
            },
            getMonthlyPriceFrom(){
                return this.$store.state.searchMonthlyPricesFrom;
            },
            getMonthlyPriceTo(){
                return this.$store.state.searchMonthlyPricesTo;
            },
            getMileageFrom(){
                return this.$store.state.searchMileageFrom;
            },
            getMileageTo(){
                return this.$store.state.searchMileageTo;
            },
            getKilometresFrom(){
                return this.$store.state.searchKilometresFrom;
            },
            getKilometresTo(){
                return this.$store.state.searchKilometresTo;
            },
            getEngineSizeFrom(){
                return this.$store.state.searchEngineSizeFrom;
            },
            getEngineSizeTo(){
                return this.$store.state.searchEngineSizeTo;
            },
            getFreeText(){
                return this.$store.state.freeText;
            },
            showFilters(){
                return this.$route.fullPath
                        .replace('/s-1', '')
                        .replace(/\/page-[0-9]+/g, '')
                        .replace(/\/sortby-[a-zA-Z0-9-]+/g, '')
                        .replace(/\/$/g, '')
                    !== '/used-cars';
            },
        },
        mounted(){

        },
        methods:{
            removeFilter(){
                // Remove the button from the DOM
                const button = event.target.closest('button');
                if (button && button.parentNode) {

                    // Makes
                    const filteredMakes = this.$store.state.searchMakeDesktop.filter(item => item !== button.closest('div').innerText.replace(new RegExp(' ', 'g'), '+'));
                    this.$store.commit('setSearchMakeDesktop', filteredMakes);

                    // Models
                    const filteredModels = this.$store.state.searchModelDesktop.filter(item => item !== button.closest('div').innerText.replace(new RegExp(' ', 'g'), '+'));
                    this.$store.commit('setSearchModelDesktop', filteredModels);

                    // Locations
                    const filteredLocations = this.$store.state.searchLocations.filter(item => item !== button.closest('div').innerText);
                    this.$store.commit('setSearchLocations', filteredLocations);

                    // Gearboxes
                    const filteredTransmissionType = this.$store.state.searchTransmissionType.filter(item => item !== button.closest('div').innerText);
                    this.$store.commit('setSearchTransmissionType', filteredTransmissionType);

                    // Fuel Types
                    const filteredFuelType = this.$store.state.searchFuelType.filter(item => item !== button.closest('div').innerText.replace(new RegExp(' ', 'g'), '+'));
                    this.$store.commit('setSearchFuelType', filteredFuelType);

                    // Body Types
                    const filteredBodyTypes = this.$store.state.searchBodyType.filter(item => item !== button.closest('div').innerText.replace(new RegExp(' ', 'g'), '+'));
                    this.$store.commit('setSearchBodyType', filteredBodyTypes);

                    // Colours
                    const colours = this.$store.state.searchColour.filter(item => item !== button.closest('div').innerText.replace(new RegExp(' ', 'g'), '+'));
                    this.$store.commit('setSearchColour', colours);

                    // Seats
                    const seats = this.$store.state.searchSeats.filter(item => parseInt(item) !== parseInt(button.closest('div').innerText));
                    this.$store.commit('setSearchSeats', seats);

                    // Doors
                    const doors = this.$store.state.searchDoors.filter(item => parseInt(item) !== parseInt(button.closest('div').innerText));
                    this.$store.commit('setSearchDoors', doors);

                    // Safety Ratings
                    const safetyRatings = this.$store.state.searchSafetyRating.filter(item => parseInt(item) !== parseInt(button.closest('div').innerText));
                    this.$store.commit('setSearchSafetyRating', safetyRatings);

                    // Fuel Consumption
                    const fuelConsumptions = this.$store.state.searchFuelConsumption.filter(item => parseInt(item) !== parseInt(button.closest('div').innerText));
                    this.$store.commit('setSearchFuelConsumption', fuelConsumptions);

                    // Environmental
                    const environmental = this.$store.state.searchEnvironmental.filter(item => parseInt(item) !== parseInt(button.closest('div').innerText.replace(/\+/g, "")));
                    this.$store.commit('setSearchEnvironmental', environmental);

                    // Engine Powers
                    const enginePowers = this.$store.state.searchEnginePower.filter(item => parseInt(item) !== parseInt(button.closest('div').innerText));
                    this.$store.commit('setSearchEnginePower', enginePowers);

                    // Accelerations
                    const accelerations = this.$store.state.searchAcceleration.filter(item => parseInt(item) !== parseInt(button.closest('div').innerText));
                    this.$store.commit('setSearchAcceleration', accelerations);

                    // Year From
                    if(parseInt(this.$store.state.searchYearFrom) == parseInt(button.closest('div').innerText.replace('From:', ""))){
                        this.$store.commit('setSearchYearFrom', null);
                    }

                    // Year To
                    if(parseInt(this.$store.state.searchYearTo) == parseInt(button.closest('div').innerText.replace('To:', ""))){
                        this.$store.commit('setSearchYearTo', null);
                    }

                    // Price From
                    if(parseInt(this.$store.state.searchPriceFrom) == parseInt(button.closest('div').innerText.replace('From: €', ''))){
                        this.$store.commit('setSearchPriceFrom', null);
                    }

                    // Price To
                    if(parseInt(this.$store.state.searchPriceTo) == parseInt(button.closest('div').innerText.replace('To: €', ''))){
                        this.$store.commit('setSearchPriceTo', null);
                    }

                    // Deposit
                    if(parseInt(this.$store.state.searchFinanceDeposit) == parseInt(button.closest('div').innerText.replace('Dept. €', ''))){
                        this.$store.commit('setSearchFinanceDeposit', null);
                    }

                    // Monthly Price From
                    if(parseInt(this.$store.state.searchMonthlyPricesFrom) == parseInt(button.closest('div').innerText.replace('From: €', ''))){
                        this.$store.commit('setSearchMonthlyPricesFrom', null);
                    }

                    // Monthly Price To
                    if(parseInt(this.$store.state.searchMonthlyPricesTo) == parseInt(button.closest('div').innerText.replace('To: €', ''))){
                        this.$store.commit('setSearchMonthlyPricesTo', null);
                    }

                    // Mileage From
                    if(parseInt(this.$store.state.searchMileageFrom) == parseInt(button.closest('div').innerText.replace('km', ''))){
                        this.$store.commit('setSearchMileageFrom', null);
                    }

                    // Mileage To
                    if(parseInt(this.$store.state.searchMileageTo) == parseInt(button.closest('div').innerText.replace('km', ''))){
                        this.$store.commit('setSearchMileageTo', null);
                    }

                    // Kilometres From
                    if(parseInt(this.$store.state.searchKilometresFrom) == parseInt(button.closest('div').innerText.replace('km', ''))){
                        this.$store.commit('setSearchKilometresFrom', null);
                    }

                    // Kilometres To
                    if(parseInt(this.$store.state.searchKilometresTo) == parseInt(button.closest('div').innerText.replace('km', ''))){
                        this.$store.commit('setSearchKilometresTo', null);
                    }

                    // Engine Size From
                    if(parseFloat(this.$store.state.searchEngineSizeFrom) == parseFloat(button.closest('div').innerText.replace('L', ''))){
                        this.$store.commit('setSearchEngineSizeFrom', null);
                    }

                    // Engine Size To
                    if(parseFloat(this.$store.state.searchEngineSizeTo) == parseFloat(button.closest('div').innerText.replace('L', ''))){
                        this.$store.commit('setSearchEngineSizeTo', null);
                    }

                    // Free Text
                    if(this.$store.state.freeText == button.closest('div').innerText){
                        this.$store.commit('setFreeText', null);
                        this.$store.commit('setFreeTextAny', null);
                        this.updateUrlAction();
                        this.updateVueRouter();
                    }

                    this.updateUrlAction();
                }
            },
            updateVueRouter() {
                let params = this.$store.state.urlParams;
                if(params.sortby == 'price-desc'){
                    //Don't put price-desc on the url (default)
                    delete params.sortby;
                }
                let newQueryString = '/' + this.$router.currentRoute.name + '/' + Object.keys(params).map(key => key + '-' + this.$store.state.urlParams[key]).join('/');
                let currentQueryString = this.$router.currentRoute.fullPath;
                if (newQueryString != currentQueryString) {
                    this.$router.push({path: newQueryString.toLowerCase()});
                }
            },
            ...mapActions([
                'updateUrlAction',
                'fetchSearchResults'
            ]),
        }
    }
</script>

<style scoped>
</style>
